import axios from "axios";

export default {

  data: () => ({
    payments_dialog: false,
    vouchers_dialog: false,
    selected: {},
    user: {},

    payments: [],
    vouchers: [],
    live_btn: 'grey',
    prev_btn: '',
  }),


  mounted() {
    window.scrollTo(0, 0);
    this.get_user()
    this.get_payments()
  },

  methods: {
    async get_user() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/users/me',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          console.log(res.data.data.user);
          this.user = res.data.data.user

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async get_payments() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/payments',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data.data.payments);
          this.live_btn = 'grey'
          this.prev_btn = ''
          this.vouchers = []
          this.payments = res.data.data.payments
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async get_vouchers() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/vouchers',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data);
          this.live_btn = ''
          this.prev_btn = 'grey'
          this.payments = []
          this.vouchers = res.data.data.vouchers
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    open_payment_dialog(data) {
      this.payments_dialog = true
      this.selected = data
    },

    open_voucher_dialog(data) {
      this.vouchers_dialog = true
      this.selected = data
    },

    cut(num) {
      let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return x.toFixed(2)
    }

  }
};